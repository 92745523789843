import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
} from 'react';
import { twMerge } from 'tailwind-merge';

interface Props
  extends PropsWithChildren,
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {}

const PrimaryButton = ({
  children,
  disabled,
  className,
  type = 'button',
  ...rest
}: Props) => {
  return (
    <button
      type={type}
      className={twMerge(
        'w-auto px-4 py-2 rounded-lg text-sm font-semibold bg-green-700 text-white hover:bg-green-800 disabled:cursor-not-allowed disabled:bg-gray-400',
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
