import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  error?: string;
  className?: string;
  isRequired?: boolean;
}

const InputText = forwardRef<HTMLInputElement, Props>(
  ({ label, className, error, isRequired, type, ...props }, ref) => {
    return (
      <div className="space-y-2">
        <label
          htmlFor={props.name}
          className="block text-sm font-medium text-gray-900"
        >
          {label} {isRequired && <span className="text-red-500">*</span>}
        </label>
        <div>
          <input
            ref={ref}
            type={type}
            id={props.name}
            className={twMerge(
              'block w-full rounded-md placeholder:text-gray-400 border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700 sm:text-sm',
              className
            )}
            {...props}
          />
          {error && <span className="text-red-500 text-sm">{error}</span>}
        </div>
      </div>
    );
  }
);

export default InputText;
