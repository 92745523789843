import { format } from 'date-fns';

export function calculateAge(dob: string): number {
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
}

export function getFileExtension(filename: string) {
  // Split the filename by the dot and get the last part as the extension
  const parts = filename.split('.');
  return parts.length > 1 ? parts.pop()?.toLowerCase() : '';
}

export function getFileName(filename: string) {
  // Get the part of the filename before the last dot
  const parts = filename.split('.');
  if (parts.length > 1) {
    parts.pop();
  }
  return parts.join('.');
}

export function toSnakeCase(text: string) {
  return text
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '_') // Replace spaces with underscores
    .replace(/[^\w_]/g, ''); // Remove any non-word characters (except underscores)
}

export const formatDateTimeToISO = (date: string, time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  const dateObj = new Date(`${date}T${time}:00`);

  const offset = -dateObj.getTimezoneOffset();
  const sign = offset >= 0 ? '+' : '-';
  const pad = (num: number) => num.toString().padStart(2, '0');
  const offsetHours = Math.abs(Math.floor(offset / 60));
  const offsetMinutes = Math.abs(offset % 60);
  const timezoneFormatted = `${sign}${pad(offsetHours)}:${pad(offsetMinutes)}`;

  const timestampWithTimeZone = `${dateObj.getFullYear()}-${pad(
    dateObj.getMonth() + 1
  )}-${pad(dateObj.getDate())}T${pad(hours)}:${pad(
    minutes
  )}:00.000${timezoneFormatted}`;

  return timestampWithTimeZone;
};

export const formatTimeFromDate = (date: Date) => {
  return format(new Date(date), 'HH:mm');
};

export const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour += 1) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hour12 = hour % 12 || 12;
      const amPm = hour < 12 ? 'AM' : 'PM';
      const time = `${hour12.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')} ${amPm}`;
      const value = `${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}`;

      times.push({
        id: value,
        name: time,
      });
    }
  }
  return times;
};

/**
 * Converts a date string to an ISO string with the time set to the current time
 * @param date - The date string to convert 2021-10-15
 * @returns The ISO string 2021-10-15T11:06:58.000Z
 */
export const formatDateToISO = (date: string) => {
  const today = new Date();
  const formattedDate = format(new Date(date), 'yyyy-MM-dd');
  return new Date(
    `${formattedDate}T${
      today.toTimeString().split(' ')[0]
    }.${today.getMilliseconds()}Z`
  ).toISOString();
};

export const splitName = (name: string) => {
  const nameParts = name.split(' ');
  const firstName = nameParts.slice(0, nameParts.length - 1).join(' ');
  const lastName = nameParts[nameParts.length - 1];
  return { firstName, lastName };
};
