'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { createUser, updateUserAuth } from '@/app/actions/auth';
import { isUserExists } from '@/app/actions/user';
import InputText from '@/app/components/ui/Input';
import PrimaryButton from '@/app/components/ui/PrimaryButton';
import { splitName } from '@/utils/functions';
import { createClient } from '@/utils/supabase/client';

import { loginSchema, LoginSchema } from '../_authSchema/schema';

const LoginContent = () => {
  const router = useRouter();
  const supabase = createClient();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [authError, setAuthError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
  });

  // Use to check the session after redirect from authentication e.g Google auth sign in/up
  useEffect(() => {
    const checkSession = async () => {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession();

        if (session) {
          setIsLoggingIn(true);
          // check if user exists in users table
          const userExists = await isUserExists(session?.user?.email!);

          if (userExists) {
            // if user exists, redirect to leads page
            return router.push('/leads');
          }

          if (session.user.app_metadata.provider === 'google') {
            // if user is not exists, create user in users table
            const userMetadata = session.user.user_metadata;
            const { firstName, lastName } = splitName(userMetadata.full_name);
            await createUser({
              id: session.user.id,
              firstName,
              lastName,
              email: userMetadata.email,
              avatar: userMetadata.avatar_url,
              sessionData: session,
              userData: session.user,
              city: '',
              state: '',
              zipCode: '',
              phoneNumber: '',
              streetAddress: '',
            });
            setIsLoggingIn(false);
            return router.push('/leads');
          }
        }
      } catch (error) {
        setIsLoggingIn(false);
      }
    };
    checkSession();
  }, [router, supabase.auth]);

  const handleSignin = async (data: LoginSchema) => {
    try {
      setAuthError(null);
      const { data: authData, error } = await supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
      });

      if (error) {
        throw error;
      }

      if (authData) {
        await updateUserAuth({
          sessionData: authData.session,
          userData: authData.user,
        });
      }
      return router.push('/leads');
    } catch (error) {
      const err = error as Error;
      setAuthError(err.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setIsLoggingIn(true);
      await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
        },
      });
    } catch (error) {
      setIsLoggingIn(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-2xl font-medium mb-4">Sign in</h1>
        <div className="text-sm text-gray-500 mb-6">
          New User?{' '}
          <Link href="/signup" className="text-green-500">
            <span className="font-medium text-green-700">
              Create an account
            </span>
          </Link>
        </div>
        <button
          type="button"
          className={classNames(
            'border border-gray-300 rounded-md px-4 py-2 w-full flex items-center justify-center gap-2 text-sm font-medium',
            {
              'opacity-50 cursor-not-allowed': isLoggingIn,
            }
          )}
          onClick={handleGoogleSignIn}
          disabled={isLoggingIn}
        >
          <Image
            src="/images/google-logo.svg"
            alt="Google"
            width={20}
            height={20}
          />
          {isLoggingIn ? 'Signing in...' : 'Sign in with Google'}
        </button>
        <div className="mt-6 relative w-64">
          <div
            aria-hidden="true"
            className="absolute inset-0 flex items-center"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-sm text-gray-500">
              or continue with
            </span>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(handleSignin)} className="mt-8">
        <div className="space-y-4">
          <InputText
            label="Email"
            placeholder="Sign in with your email address"
            error={errors.email?.message}
            required
            {...register('email')}
          />
          <InputText
            label="Password"
            type="password"
            placeholder="Provide your password"
            error={errors.password?.message}
            required
            {...register('password')}
          />
        </div>
        <div className="text-red-500 text-sm h-6 my-2">{authError}</div>
        <div className="">
          <PrimaryButton
            className="w-full"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Signing in...' : 'Sign in'}
          </PrimaryButton>
          <div className="mt-6">
            <p className="text-sm text-center text-gray-500">
              Forgot your password?{' '}
              <span className="text-green-700">
                <Link href="/reset-password">Reset password</Link>
              </span>
            </p>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoginContent;
